import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class AccountItem {
  getAccountItemInfo(id) {
    return axios.get(
      `/api/self/account-item/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getAccountItemList(id, name, account_type_id, company_id, page, page_num) {
    return axios.get(
      `/api/self/account-item/list`,
      {
        params: {
          id: id,
          name: name,
          account_type_id: account_type_id,
          company_id: company_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getAccountItemSelectList(name, company_id) {
    return axios.get(
      `/api/self/account-item/select-list`,
      {
        params: {
          name: name,
          company_id: company_id,
        },
      },
      config
    );
  }
}
